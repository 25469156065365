import { ComponentProps, FC, PropsWithChildren, ReactElement } from 'react'
import clsx from 'clsx'

type ProfileTemplateProps = Pick<ComponentProps<'div'>, 'className'> & {
  avatar: ReactElement
  firstName: string | ReactElement
  lastName: string | ReactElement
}

export const ProfileTemplate: FC<PropsWithChildren<ProfileTemplateProps>> = ({
  avatar,
  firstName,
  lastName,
  className,
  children,
}) => (
  <div className={clsx('flex flex-col gap-6', className)}>
    <div className="flex gap-3">
      <div className="h-14 w-14 shrink-0">{avatar}</div>
      <div
        className={clsx(
          'text-deep-teal-500 font-brand',
          'flex flex-1 flex-col overflow-hidden',
          'text-2xl font-medium leading-7',
        )}
      >
        <div className="truncate">{firstName}</div>
        <div className="truncate">{lastName}</div>
      </div>
    </div>

    {children}
  </div>
)
