import { ComponentProps, FC } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

type ProfileNominatedEmptyState = Pick<ComponentProps<'div'>, 'className'>

export const ProfileNominatedEmptyState: FC<ProfileNominatedEmptyState> = ({
  className
}) => (
  <div className={clsx('flex flex-col gap-1', className)}>
    <h3 className="text-deep-teal-900 text-base leading-1 font-semibold">
      Members nominated
    </h3>

    <p className="text-deep-teal-400 text-sm leading-5 font-normal">
      Hit the button to nominate a member
    </p>

    <div>
      <Link
        href="/nominations"
        className="text-orange-600 hover:text-orange-800"
      >
        Nominate member +
      </Link>
    </div>
  </div>
)