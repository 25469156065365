import { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { InvestmentFullSizeCard } from 'ui/components/content'
import { InvestmentFullSizeCardProps } from 'ui/components/content/InvestmentFullSizeCard/InvestmentFullSizeCard'

interface InvestmentsProps extends ComponentProps<'div'> {
  investments: InvestmentFullSizeCardProps['investment'][]
}

export const Investments: FC<InvestmentsProps> = ({ className, investments, ...props }) => {
  return (
    <div className={clsx('grid grid-cols-2 gap-4', className)} {...props}>
      {investments.map((investment) => (
        <InvestmentFullSizeCard className="block" key={investment.id} investment={investment} />
      ))}
    </div>
  )
}
