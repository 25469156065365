import { FC } from 'react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { UserSocialProfilePreview } from 'api/dto'
import { take } from 'lodash'
import { AmityAvatar } from 'ui/components/content/Avatar/AmityAvatar'
import { AvatarGroup } from 'ui/components/content/AvatarGroup'
import { Link } from 'ui/components/navigation'

type ProfileNominated = {
  nominations: UserSocialProfilePreview[]
}

export const ProfileNominated: FC<ProfileNominated> = ({
  nominations
}) => {
  const remainingAvatars = nominations.length - 3

  return (
    <div className="flex flex-col gap-2">
      <div className="text-deep-teal-900 text-base leading-1 font-semibold">Members nominated</div>

      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <AvatarGroup>
            {take(nominations, 3).map((nomination) => (
              <AmityAvatar className="w-6 h-6 rounded-full ring-2 ring-white flex shrink-0"
                           fileId={nomination.avatarFileId} key={nomination.id} />
            ))}
          </AvatarGroup>

          {remainingAvatars > 0 && (
            <div className="basic-text-sm leading-none font-normal text-deep-teal-400">
              + {remainingAvatars}
            </div>
          )}
        </div>

        <Link href="/nominations">
          <PlusCircleIcon className="w-6 h-6 text-orange-600 hover:cursor-pointer hover:text-orange-700" />
        </Link>
      </div>
    </div>
  )
}