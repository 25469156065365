import { FC } from 'react'
import Link from 'next/link'
import { MapIcon } from '@heroicons/react/24/solid'
import { UserSocialProfile } from 'api/src/dto'
import { LDFlag, useFlag } from 'launchdarkly'
import { AmityAvatar } from 'ui/components/content/Avatar/AmityAvatar'
import { useRoutingContext } from 'ui/lib/navigation'
import { ProfileLoadingState } from './ProfileLoadingState'
import { ProfileNominated, ProfileNominatedEmptyState } from './ProfileNominated'
import { ProfileNominatedBy } from './ProfileNominatedBy'
import { ProfileTemplate } from './ProfileTemplate'

type ProfileProps = {
  className?: string
  profile: UserSocialProfile
  isLoading: boolean
}

export const Profile: FC<ProfileProps> = ({ className, profile, isLoading }) => {
  const { baseUrl } = useRoutingContext()
  const enableMembersDiscoveryLink = useFlag(LDFlag.EnableMemberDiscoveryNavigation)

  if (isLoading) {
    return <ProfileLoadingState />
  }

  return (
    <ProfileTemplate
      className={className}
      avatar={<AmityAvatar className="h-14 w-14" fileId={profile?.avatarFileId} />}
      firstName={profile.firstName}
      lastName={profile.lastName}
    >
      <div className="flex flex-col gap-4">
        {profile.nominatedBy.length > 0 && (
          <>
            <ProfileNominatedBy nominatedBy={profile.nominatedBy} />
            <div className="bg-deep-teal-100 h-px last-of-type:hidden" />
          </>
        )}

        {profile.hasNominated.length > 0 ? (
          <ProfileNominated nominations={profile.hasNominated} />
        ) : (
          <ProfileNominatedEmptyState />
        )}

        <div className="bg-deep-teal-100 h-px last-of-type:hidden" />

        {enableMembersDiscoveryLink && (
          <>
            <Link
              className={'leading-1 flex items-center font-semibold text-orange-600'}
              href={`${baseUrl}/discovery`}
            >
              <span className="mr-2">Discover meetperry members</span>
              <MapIcon className="h-4 w-4" />
            </Link>
            <div className="bg-deep-teal-100 h-px last-of-type:hidden" />
          </>
        )}
      </div>
    </ProfileTemplate>
  )
}
