import { FC, useState } from 'react'
import { PostTargetType } from '@amityco/js-sdk'
import { AmityUiKitFeedWithProvider } from 'amity/components'
import { useMyGroups } from 'amity/src/lib'
import { Event } from 'api/cms'
import { InvestmentOpportunityWithTotalIoi, UserSocialProfile } from 'api/dto'
import { useAxios } from 'api/lib'
import { useSession } from 'auth'
import clsx from 'clsx'
import { startOfToday } from 'date-fns'
import { LDFlag, useFlag } from 'launchdarkly'
import {
  Card,
  CardSection,
  Events,
  EventsCarousel,
  MemberSummary,
  MemberSummaryLoading,
} from 'ui/components/content'
import { useRoutingContext } from 'ui/lib/navigation'
import { MainLayout } from '@perry/app/components/layouts/MainLayout'
import { Groups, GroupsCarousel } from './Groups'
import {
  InvestmentsSection,
  Investments,
  InvestmentsCarousel,
  InvestmentsSectionLoading,
  InvestmentsLoading,
  InvestmentsCarouselLoading,
  InvestmentsEmptyState,
} from './Investments'
import { Profile } from './Profile'
import { useSlidesToShow } from './useSlidesToShow'

const MAX_INVESTMENTS_ON_THE_PAGE = 4
const MAX_EVENTS_ON_THE_PAGE = 5
const MAX_GROUPS_ON_THE_PAGE = 4

const useInvestments = (limit = MAX_INVESTMENTS_ON_THE_PAGE) => {
  // URL is the unique key for the internal SWR cache. If we move "limit" to params,
  // URL will exactly match the one on the All Investment Opportunities page. This leads to
  // caching response between these two pages. To avoid this we make "limit" part of the key.
  return useAxios<InvestmentOpportunityWithTotalIoi[]>(
    `/api/investment-opportunities?limit=${limit}&sourceType=core`,
  )
}

const useInvestmentsCount = () => {
  const enableOffPisteInvestments = useFlag(LDFlag.EnableOffPisteInvestments)

  return useAxios<number>(
    '/api/investment-opportunities/count' + (enableOffPisteInvestments ? '' : '?sourceType=core'),
  )
}

const useEvents = (minEventDate: Date = startOfToday(), limit = MAX_EVENTS_ON_THE_PAGE) => {
  // URL is the unique key for the internal SWR cache. If we move "limit" to params,
  // URL will exactly match the one on the All Investment Opportunities page. This leads to
  // caching response between these two pages. To avoid this we make "limit" part of the key.
  const url = new URLSearchParams({
    limit: limit.toString(),
    minEventDate: minEventDate.toISOString(),
  })
  return useAxios<Event[]>(`/api/events?${url}`)
}

const useProfile = (userId: number) =>
  useAxios<UserSocialProfile>(`/api/users/${userId}/social-profile`)

export const Main: FC = () => {
  const { baseUrl, push } = useRoutingContext()
  const { user, isLoading: isUserLoading } = useSession()
  const { data: profile, isLoading: isProfileLoading } = useProfile(user.id)
  const { data: events = [], isLoading: isEventsLoading } = useEvents()
  const { data: investments = [], isLoading: isInvestmentsLoading } = useInvestments()
  const { data: investmentsCount, isLoading: isInvestmentsCountLoading } = useInvestmentsCount()
  const { groups, isLoading: isGroupsLoading } = useMyGroups(MAX_GROUPS_ON_THE_PAGE)

  const [isAmityFeedLoaded, setIsAmityFeedLoaded] = useState(false)
  const slidesToShow = useSlidesToShow()

  // Sync loading state
  const isLoading =
    isProfileLoading ||
    isUserLoading ||
    isInvestmentsLoading ||
    isInvestmentsCountLoading ||
    isGroupsLoading ||
    isEventsLoading

  const banner = profile && (
    <div className="bg-taupe-200 sm:hidden">
      <div className="bg-deep-teal-700 rounded-b-lg px-4 py-6">
        {isLoading ? (
          <MemberSummaryLoading />
        ) : (
          <MemberSummary className="sm:hidden" profile={profile} />
        )}
      </div>
    </div>
  )

  return (
    <MainLayout
      className={clsx(
        'mx-auto flex flex-col gap-4 px-4 py-6',
        'sm:max-w-[632px] sm:py-8',
        'w-full xl:grid xl:grid-rows-[min-content_auto]',
        'xl:max-w-7xl xl:grid-cols-4 xl:px-8',
      )}
      title="meetperry | Home"
      banner={banner}
    >
      <Card className="hidden sm:block xl:self-baseline">
        <Profile profile={profile} isLoading={isLoading} />
      </Card>
      <div className="col-span-2">
        {!isLoading ? (
          <InvestmentsSection count={investmentsCount}>
            {investments.length ? (
              <>
                <Investments investments={investments} className="hidden xl:grid" />
                <InvestmentsCarousel
                  investments={investments}
                  slidesToShow={slidesToShow}
                  className="xl:hidden"
                />
              </>
            ) : (
              <InvestmentsEmptyState />
            )}
          </InvestmentsSection>
        ) : (
          <InvestmentsSectionLoading>
            <InvestmentsLoading className="hidden xl:grid" />
            <InvestmentsCarouselLoading slidesToShow={slidesToShow} className="xl:hidden" />
          </InvestmentsSectionLoading>
        )}
      </div>
      <div className="flex flex-col gap-4 xl:col-start-4 xl:row-span-full">
        <CardSection
          title="Events"
          href={events.length > 0 ? '/events' : undefined}
          isLoading={isLoading}
        >
          <Events events={events} isLoading={isLoading} className="hidden xl:flex" />
          <EventsCarousel
            events={events}
            isLoading={isLoading}
            slidesToShow={slidesToShow}
            className="xl:hidden"
          />
        </CardSection>

        <CardSection
          title="My groups"
          href={groups.length > 0 ? '/groups' : undefined}
          isLoading={isLoading}
        >
          <Groups groups={groups} isLoading={isLoading} className="hidden xl:flex" />
          <GroupsCarousel
            slidesToShow={slidesToShow}
            groups={groups}
            isLoading={isLoading}
            className="xl:hidden"
          />
        </CardSection>
      </div>
      <section className="gap-4 xl:col-span-2 xl:col-start-2 xl:row-start-2">
        {isAmityFeedLoaded && (
          <div className="text-deep-teal-900 mb-2 text-lg font-semibold xl:text-xl">
            Content & Commentary
          </div>
        )}
        <AmityUiKitFeedWithProvider
          router={{ push }}
          baseUrl={baseUrl}
          onLoaded={() => setIsAmityFeedLoaded(true)}
          targetType={PostTargetType.GlobalFeed}
        />
      </section>
    </MainLayout>
  )
}
