import { ComponentProps, FC } from 'react'
import { Carousel, GroupCard, GroupCardLoading, GroupCardProps } from 'ui/components/content'
import GroupsEmptyState from './GroupsEmptyState'

type GroupsCarouselProps = Pick<GroupCardProps, 'urlPrefix'> &
  Pick<ComponentProps<'div'>, 'className'> & {
    groups: Amity.Community[]
    isLoading: boolean
    slidesToShow?: number
  }

export const GroupsCarousel: FC<GroupsCarouselProps> = ({
  groups,
  isLoading,
  urlPrefix,
  slidesToShow,
  className,
}) => {
  if (isLoading) {
    return <GroupCardLoading className={className} />
  }

  if (!groups.length) {
    return <GroupsEmptyState className={className} />
  }

  return (
    <Carousel className={className} slidesToShow={slidesToShow}>
      {groups.map((group) => (
        <GroupCard group={group} key={group.communityId} urlPrefix={urlPrefix} />
      ))}
    </Carousel>
  )
}
